import IntlMessageFormat, { Formats } from 'intl-messageformat';
import { LocaleHelper } from './LocaleHelper';
import { range } from 'lodash';

// Regex format to identify the content to format. The content must start with @@ followed by the translation key and option parameters to pass runtime values.
// Added support for translation key's that have product followed by "." character
export const TRANSLATION_REGEX_FORMAT = /^@@(\w*\.?\w*)\s*(\((.*?)\))?/; // /^@@(\w*)\s*(\((.*?)\))?/;

// Regex format to identify the name of the property to bind the value. The value can be a combination of Alphabets, Number, -, . and _. Ex - ::state.personal-details1.first_Name
export const TRANSLATION_VALUE_FORMAT = /::([a-zA-Z0-9.-_]*)/;

// Regex format to identify the runtime property name to pass into translation object.
export const TRANSLATION_KEY_FORMAT = /(\w*-?\w*):/;

// List of all pre-configured formats
// TODO - We need a way to extend these formats by client app
const ALL_FORMATS = {
  useInternationalDates: false,
  number: {
    currency: {
      style: 'currency',
      currency: null
    },
    USD: {
      style: 'currency',
      currency: 'USD'
    },
    CAD: {
      style: 'currency',
      currency: 'CAD'
    },
    percent: {
      style: 'percent'
    }
  },
  date: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    shorter: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    'short-time': {
      hour: '2-digit',
      minute: '2-digit'
    },
    'long-time': {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    },
    'short-date-time': {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    },
    'long-date-time': {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    },
    default: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  }
};

const INTERNATIONAL_DATE_FORMATS = {
  short: {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  },
  shorter: {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  },
  long: {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  },
  'short-time': {
    hour: '2-digit',
    minute: '2-digit'
  },
  'long-time': {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  },
  'short-date-time': {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  },
  'long-date-time': {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  },
  default: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
};

// Map the local country to its corresponding ISO currency code
export const COUNTRY_TO_CURRENCY_CODE = {
  ad: 'EUR',
  ae: 'AED',
  af: 'AFN',
  ag: 'XCD',
  ai: 'XCD',
  al: 'ALL',
  am: 'AMD',
  ao: 'AOA',
  ar: 'ARS',
  as: 'USD',
  at: 'EUR',
  au: 'AUD',
  aw: 'AWG',
  ax: 'EUR',
  az: 'AZN',
  ba: 'BAM',
  bb: 'BBD',
  bd: 'BDT',
  be: 'EUR',
  bf: 'XOF',
  bg: 'BGN',
  bh: 'BHD',
  bi: 'BIF',
  bj: 'XOF',
  bl: 'EUR',
  bm: 'BMD',
  bn: 'BND',
  bo: 'BOB',
  bq: 'USD',
  br: 'BRL',
  bs: 'BSD',
  bt: 'BTN',
  bv: 'NOK',
  bw: 'BWP',
  by: 'BYN',
  bz: 'BZD',
  ca: 'CAD',
  cc: 'AUD',
  cd: 'CDF',
  cf: 'XAF',
  cg: 'XAF',
  ch: 'CHF',
  ci: 'XOF',
  ck: 'NZD',
  cl: 'CLP',
  cm: 'XAF',
  cn: 'CNY',
  co: 'COP',
  cr: 'CRC',
  cv: 'CVE',
  cw: 'ANG',
  cx: 'AUD',
  cy: 'EUR',
  cz: 'CZK',
  de: 'EUR',
  dj: 'DJF',
  dk: 'DKK',
  dm: 'XCD',
  do: 'DOP',
  dz: 'DZD',
  ec: 'USD',
  ee: 'EUR',
  eg: 'EGP',
  eh: 'EHP',
  er: 'ERN',
  es: 'EUR',
  et: 'ETB',
  fi: 'EUR',
  fj: 'FJD',
  fk: 'FKP',
  fm: 'USD',
  fo: 'DKK',
  fr: 'EUR',
  ga: 'XAF',
  gb: 'GBP',
  gd: 'XCD',
  ge: 'GEL',
  gf: 'EUR',
  gg: 'GBP',
  gh: 'GHS',
  gi: 'GIP',
  gl: 'DKK',
  gm: 'GMD',
  gn: 'GNF',
  gp: 'EUR',
  gq: 'XAF',
  gr: 'EUR',
  gt: 'GTQ',
  gu: 'USD',
  gw: 'XOF',
  gy: 'GYD',
  hk: 'HKD',
  hm: 'AUD',
  hn: 'HNL',
  hr: 'HRK',
  ht: 'HTG',
  hu: 'HUF',
  id: 'IDR',
  ie: 'EUR',
  il: 'ILS',
  im: 'GBP',
  in: 'INR',
  io: 'GBP',
  iq: 'IQD',
  is: 'ISK',
  it: 'EUR',
  je: 'GBP',
  jm: 'JMD',
  jo: 'JOD',
  jp: 'JPY',
  ke: 'KES',
  kg: 'KGS',
  kh: 'KHR',
  ki: 'AUD',
  km: 'KMF',
  kn: 'XCD',
  kr: 'KRW',
  kw: 'KWD',
  ky: 'KYD',
  kz: 'KZT',
  la: 'LAK',
  lb: 'KBP',
  lc: 'XCD',
  li: 'CHF',
  lk: 'LKR',
  lr: 'LRD',
  ls: 'LSL',
  lt: 'EUR',
  lu: 'EUR',
  lv: 'EUR',
  ly: 'LYD',
  ma: 'MAD',
  mc: 'EUR',
  md: 'MDL',
  me: 'EUR',
  mf: 'EUR',
  mg: 'MGA',
  mh: 'USD',
  mk: 'MKD',
  ml: 'XOF',
  mm: 'MMK',
  mn: 'MNT',
  mo: 'MOP',
  mp: 'USD',
  mq: 'EUR',
  mr: 'MRU',
  ms: 'XCD',
  mt: 'EUR',
  mu: 'MUR',
  mv: 'MVR',
  mw: 'MWK',
  mx: 'MXN',
  my: 'MYR',
  mz: 'MZN',
  na: 'NAD',
  nc: 'XPF',
  ne: 'XOF',
  nf: 'AUD',
  ng: 'NGN',
  ni: 'NIO',
  nl: 'EUR',
  no: 'NOK',
  np: 'NPR',
  nr: 'AUD',
  nu: 'NZD',
  nz: 'NZD',
  om: 'OMR',
  pa: 'USD',
  pe: 'PEN',
  pf: 'XPF',
  pg: 'PGK',
  ph: 'PHP',
  pk: 'PKR',
  pl: 'PLN',
  pm: 'EUR',
  pn: 'NZD',
  pr: 'USD',
  pt: 'EUR',
  pw: 'USD',
  py: 'PYG',
  qa: 'QAR',
  re: 'EUR',
  ro: 'RON',
  rs: 'RSD',
  ru: 'RUB',
  rw: 'RWF',
  sa: 'SAR',
  sb: 'SBD',
  sc: 'SCR',
  se: 'SEK',
  sg: 'SGD',
  sh: 'SHP',
  si: 'EUR',
  sj: 'NOK',
  sk: 'EUR',
  sl: 'SLL',
  sm: 'EUR',
  sn: 'XOF',
  so: 'SOS',
  sr: 'SRD',
  ss: 'SSP',
  st: 'STN',
  sv: 'SVC',
  sx: 'ANG',
  sz: 'SZL',
  tc: 'USD',
  td: 'XAF',
  tf: 'EUR',
  tg: 'XOF',
  th: 'THB',
  tj: 'TJS',
  tk: 'NZD',
  tl: 'USD',
  tm: 'TMT',
  tn: 'TND',
  to: 'TOP',
  tr: 'TRY',
  tt: 'TTD',
  tv: 'AUD',
  tw: 'TWD',
  tz: 'TZS',
  ua: 'UAH',
  ug: 'UGX',
  um: 'USD',
  us: 'USD',
  uy: 'UYU',
  uz: 'UZS',
  va: 'EUR',
  vc: 'XCD',
  ve: 'VES',
  vg: 'USD',
  vi: 'USD',
  vn: 'VND',
  vu: 'VUV',
  wf: 'XPF',
  ws: 'WST',
  ye: 'YER',
  yt: 'EUR',
  za: 'ZAR',
  zm: 'ZMW',
  zw: 'ZWL'
};

// Max allowed digits in a number type
const MAX_NO_OF_DIGITS_ALLOWED = 20;
// Regex formats
const NUMBER_FIXED_DIGITS_FORMAT = /^\d+-digit$/;
const NUMBER_MIN_DIGITS_FORMAT = /^\d+-digit-min$/;
const NUMBER_MAX_DIGITS_FORMAT = /^\d+-digit-max$/;

// This constant used to validate the translation message which contains {date} parameter
const INTL_MESSAGE_DATE_FORMAT = /\{\s*(\w*)\s*,\s*date\s*,\s?(\w*)\s*?\}/;

// This constant used to validate the translation message which contains html tags. The [^>]* makes sure there are characters not a '>' between the < and >.
const HTML_TAGS_FORMAT = /<[^>]*>/g;

interface INumberFormat {
  decimalSeparator: string;
  thousandSeparator: string;
}

interface IDateFormat {
  months: { short: string; long: string }[];
  weeks: { short: string; long: string }[];
}

interface ICultureFormats {
  numberFormat: INumberFormat;
  dateFormat: IDateFormat;
}

export class FormatHelper {
  private static cultureFormat: ICultureFormats = {
    numberFormat: {
      decimalSeparator: '.',
      thousandSeparator: ','
    },
    dateFormat: {
      months: [],
      weeks: []
    }
  };

  static useInternationalDateFormats() {
    ALL_FORMATS.date = INTERNATIONAL_DATE_FORMATS;
    ALL_FORMATS.useInternationalDates = true;
  }

  static deleteLTRMark(value?: string) {
    return value.replace(/\u{200E}/ug, '');
  }

  private static dateFormatter(locale: string, options: any, date: Date) {
    return (new Intl.DateTimeFormat(locale, options).format(date));
  }

  // This method returns the result formatted to date. The format param accepts either list pre-configured ALL_FORMATS or Intl.DateTimeFormat option object
  static formatDate(value: string | Date | number, format?: any, locale?: string): string {
    // If value is empty, then don't process
    if (value === '') {
      return value;
    }

    let parsedDate: Date;
    let userLocale = !locale ? LocaleHelper.getUserLocale() : locale;

    if (userLocale.toLowerCase() === 'es-us') {
      // Change the locale to Spanish-Puerto Rico to pick up US date formats.
      userLocale = 'es-pr';
    }

    if (userLocale.toLowerCase() === 'en-ca') {
      // Change the locale to English-Great Britain to use the "preferred" date format for Canada.
      userLocale = 'en-gb';
    }

    // if passed as string, parse it to date object
    if (typeof value === 'string') {
      parsedDate = new Date(FormatHelper.deleteLTRMark(value));
    }
    else if (value instanceof Date) {
      parsedDate = value;
    }
    else {
      console.error(`Invalid date input: ${value}`);
      return;
    }

    let dateOptions = ALL_FORMATS.date.default as Intl.DateTimeFormatOptions;

    if (format === 'relative') {
      const moment = LocaleHelper.getMoment();
      return moment(parsedDate).fromNow();
    }
    else if (format) {
      if (ALL_FORMATS.date.hasOwnProperty(format)) {
        dateOptions = { ...ALL_FORMATS.date[format] };
      }
      else if (format instanceof Object) {
        dateOptions = format;
      }

      return FormatHelper.formatDateWithOptions(parsedDate, userLocale, dateOptions);
    }

    if (ALL_FORMATS.useInternationalDates) {
      return FormatHelper.formatInternationalDateWithOptions(parsedDate, dateOptions);
    }
    else {
      return FormatHelper.formatDateWithOptions(parsedDate, userLocale, dateOptions);
    }
  }

  private static formatDateWithOptions(parsedDate: Date, userLocale, dateOptions: Intl.DateTimeFormatOptions) {
    let formattedDate = '';

    if (!dateOptions) {
      console.error('invalid dateOptions');
    }

    // if week name is requested, get the value from cached and delete the props from dateOptions
    if (dateOptions.weekday && FormatHelper.cultureFormat.dateFormat.weeks.length > 0) {
      const formattedWeekName = FormatHelper.cultureFormat.dateFormat.weeks[parsedDate.getUTCDay()];
      formattedDate = formattedWeekName[dateOptions.weekday] || formattedWeekName.short;
      delete dateOptions.weekday;
    }

    // Use the new date display format if requested
    if (ALL_FORMATS.useInternationalDates) {
      formattedDate = `${formattedDate} ${FormatHelper.formatInternationalDateWithOptions(parsedDate, dateOptions)}`;

      // Deleting the parsed props from dateOptions as it is already formatted
      if (dateOptions.day) {
        delete dateOptions.day;
      }

      if (dateOptions.month) {
        delete dateOptions.month;
      }

      if (dateOptions.year) {
        delete dateOptions.year;
      }
    }

    if (Object.keys(dateOptions).length > 0) {
      if (!dateOptions.hasOwnProperty('timeZone')) {
        // Use UTC as the default timezone.
        dateOptions.timeZone = 'UTC';
      }

      formattedDate = `${formattedDate} ${FormatHelper.dateFormatter(userLocale, dateOptions, parsedDate)}`;
    }

    return formattedDate.trim();
  }

  // This method returns an international standard format (DD Mmm YYYY) of the given date. monthType can be either short or long
  private static formatInternationalDateWithOptions(parsedDate: Date, dateOptions: Intl.DateTimeFormatOptions) {
    let formattedDate;
    let formattedMonth;
    let formattedYear;

    if (dateOptions.day) {
      formattedDate = parsedDate.getUTCDate().toString().padStart(2, '0');
    }

    if (dateOptions.month) {
      const monthOption = FormatHelper.cultureFormat.dateFormat.months[parsedDate.getUTCMonth()];
      formattedMonth = monthOption[dateOptions.month] || monthOption.short;
    }

    if (dateOptions.year) {
      formattedYear = parsedDate.getUTCFullYear();
    }

    return `${formattedDate || ''} ${formattedMonth || ''} ${formattedYear || ''}`.trim();
  }

  // This method returns the result formatted to number. The format param accepts either list pre-configured ALL_FORMATS or Intl.NumberFormat option object
  static formatNumber(value: any, format?: any, locale?: string): string {
    if (isNaN(value)) {
      return value;
    }

    let numberOptions: any = { maximumFractionDigits: MAX_NO_OF_DIGITS_ALLOWED };

    if (format) {
      if (ALL_FORMATS.number[format]) {
        numberOptions = ALL_FORMATS.number[format];
      }
      else if (typeof format === 'string') {
        const matchDigits = format.match(/\d+/);

        if (matchDigits) {
          const digits = matchDigits[0];

          if (NUMBER_FIXED_DIGITS_FORMAT.test(format)) {
            numberOptions = {
              minimumFractionDigits: digits,
              maximumFractionDigits: digits
            };
          }
          else if (NUMBER_MIN_DIGITS_FORMAT.test(format)) {
            numberOptions = {
              minimumFractionDigits: digits,
              maximumFractionDigits: MAX_NO_OF_DIGITS_ALLOWED
            };
          }
          else if (NUMBER_MAX_DIGITS_FORMAT.test(format)) {
            numberOptions = { maximumFractionDigits: digits };
          }
        }
      }
      else {
        numberOptions = format;
      }
    }

    const numberFormat = new Intl.NumberFormat(locale || LocaleHelper.getUserLocale(), numberOptions).format(value);

    // CurrencyBox - For en-us culture Intl number formats the negative currency formatting to ($n) in IE.
    if (format && format.style && format.style.toLowerCase() === 'currency') {
      return this.formatNegativeCurrency(numberFormat);
    }
    else {
      return numberFormat;
    }
  }

  // Remove parentheses and replace with negative sign Using regEx
  static formatNegativeCurrency = (value: string) => {
    // Regex to check for parenthesis
    const expression = /^\(.*?\)/;

    if (value.match(expression)) {
      // strip out parentheses and append - at front
      return '-' + value.replace(/[\(\)]/g, '');
    }
    else {
      return value;
    }
  };

  // This method extract the key from IDS store and format the message
  // Returns type any instead of string due to disagreement in TypeScript with intl-formatmessage library.
  static formatMessage(key: string, values?: any): any {
    // If key doesn't start with @@, then it isn't require translation
    if (!key || !key.startsWith('@@')) {
      return key;
    }

    // Get the translation message
    let message = LocaleHelper.getMessage(key.substr(2));

    // If no args provided, then the message is already translated and no need for formatter
    if (!values) {
      return message;
    }

    // Intl message supports {date} parameter. Check if the message has {date} parameter then parse it to ADP standard format
    if (INTL_MESSAGE_DATE_FORMAT.test(message)) {
      /*
        INTL_MESSAGE_DATE_FORMAT regex returns the result as below array
        0 => message string
        1 => model property name
        2 => optional date format type like short, long..
      */
      const parsedMessages = message.match(INTL_MESSAGE_DATE_FORMAT);

      if (parsedMessages.length > 1) {
        const messageParam = parsedMessages[0];
        const modelName = parsedMessages[1];

        // extract the date format type only when it is provided
        const modelValue = parsedMessages.length > 2 && parsedMessages[2] ? parsedMessages[2] : null;
        const formattedDate = FormatHelper.formatDate(values[modelName], modelValue);

        // replacing the message with formattedDate
        message = message.replace(messageParam, formattedDate);
      }
    }

    // Intl message supports html tags. Check if the message has html tags then append single quotes to it before parse it
    if (HTML_TAGS_FORMAT.test(message)) {
      // find all the html tags in the translation message
      const allHtmlTags = message.match(HTML_TAGS_FORMAT);
      const uniqueHtmlTags = new Set();

      allHtmlTags.forEach((htmlTag) => {
        uniqueHtmlTags.add(htmlTag);
      });

      // append single quotes with a space before after for all the html tags before parsing it.
      uniqueHtmlTags.forEach((htmlTag: string) => {
        message = message.replace((new RegExp(htmlTag, 'g')), ` '${htmlTag}' `);
      });

      // Using formatter to format the arguments
      const formatter = new IntlMessageFormat(message, LocaleHelper.getUserLocale(), ALL_FORMATS as Partial<Formats>);
      let formattedMessage: any = formatter.format(values);

      // single quotes will be removed by intl formatter hence remove the space before, after html tags
      uniqueHtmlTags.forEach((htmlTag: string) => {
        formattedMessage = formattedMessage.replace((new RegExp(` ${htmlTag} `, 'g')), htmlTag);
      });

      return formattedMessage;
    }

    // Using formatter to format the arguments
    try {
      const formatter = new IntlMessageFormat(message, LocaleHelper.getUserLocale(), ALL_FORMATS as Partial<Formats>);
      return formatter.format(values);
    }
    catch (e) {
      console.error(`FormatHelper.formatMessage(): Error trying to format the message ${message} for locale ${LocaleHelper.getUserLocale()}`, e);
      console.error('FormatHelper.formatMessage(): Values for the message: ', values);
      return message;
    }
  }

  // This method reset the default current code
  static updateCultureFormats(countryCode) {
    ALL_FORMATS.number.currency.currency = COUNTRY_TO_CURRENCY_CODE[countryCode] || COUNTRY_TO_CURRENCY_CODE['us'];
    FormatHelper.cultureFormat.numberFormat = FormatHelper.getLocaleNumberFormat(LocaleHelper.getUserLocale());
    FormatHelper.cultureFormat.dateFormat = FormatHelper.getLocaleDateFormat();
  }

  // To return current local specific formatting information
  static getLocaleFormats(): ICultureFormats {
    return FormatHelper.cultureFormat;
  }

  // This method return the number formatting information based on the locale. If local is empty
  public static getLocaleNumberFormat(locale: string): INumberFormat {
    return {
      decimalSeparator: new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(0).replace(/\d/g, ''),
      thousandSeparator: new Intl.NumberFormat(locale, { minimumFractionDigits: 0 }).format(1000).replace(/\d/g, '')
    };
  }

  public static getCurrencyFractionDigits(currencyType: string, locale?: string) {
    const userLocale = locale || LocaleHelper.getUserLocale();
    const formattedNumber = new Intl.NumberFormat(userLocale, { style: 'currency', currency: currencyType }).format(1);
    const valueParts = formattedNumber.split(this.getLocaleNumberFormat(userLocale).decimalSeparator);
    const fractionPart = valueParts[1];
    return fractionPart ? fractionPart.length : 0;
  }

  // This method return the date formatting information based on the locale.
  private static getLocaleDateFormat(): IDateFormat {
    function toUTCDate(date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }

    const currentDate = toUTCDate(new Date());
    currentDate.setUTCMonth(0, 1);

    const userLocale = LocaleHelper.getUserLocale();
    const isFrenchLocale = userLocale.includes('fr');
    const isSpanishLocale = userLocale.includes('es');
    const months: { short: string; long: string }[] = [];
    const weeks: { short: string; long: string }[] = [];

    range(0, 12).forEach(() => {
      // Get month names
      let shortMonthName = FormatHelper.dateFormatter(userLocale, { month: 'short', timeZone: 'UTC' }, currentDate).replace('.', '');
      const longMonthName = FormatHelper.dateFormatter(userLocale, { month: 'long', timeZone: 'UTC' }, currentDate);

      // As per UX requirement, short month names must be in title case.
      if (isFrenchLocale || isSpanishLocale) {
        shortMonthName = shortMonthName.charAt(0).toUpperCase() + shortMonthName.slice(1);

        // As per UX requirement when international dates are enabled, French month names must be always 3 chars.
        if (ALL_FORMATS.useInternationalDates) {
          shortMonthName = shortMonthName.substring(0, 3);

          // June month must be spelled as JUN on both English and French, to avoid confusion with July month in French
          if (isFrenchLocale && currentDate.getUTCMonth() === 5) {
            shortMonthName = 'Jun';
          }
        }
      }

      // incrementing month
      currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
      months.push({ short: shortMonthName, long: longMonthName });
    });

    const weekBeginDate = toUTCDate(new Date());
    weekBeginDate.setUTCDate(weekBeginDate.getUTCDate() - weekBeginDate.getUTCDay());

    range(0, 7).forEach(() => {
      const shortWeekName = FormatHelper.dateFormatter(userLocale, { weekday: 'short', timeZone: 'UTC' }, weekBeginDate);
      const longWeekName = FormatHelper.dateFormatter(userLocale, { weekday: 'long', timeZone: 'UTC' }, weekBeginDate);
      weekBeginDate.setUTCDate(weekBeginDate.getUTCDate() + 1);
      weeks.push({ short: shortWeekName, long: longWeekName });
    });

    return { months: months, weeks: weeks };
  }

  public static getMonthIndex(monthName: string): string {
    if (monthName) {
      monthName = monthName.toLowerCase();
      const monthIndex = FormatHelper.cultureFormat.dateFormat.months.findIndex((e) => e.short.toLowerCase() === monthName || e.long.toLowerCase() === monthName);
      return monthIndex >= 0 ? (monthIndex + 1).toString().padStart(2, '0') : null;
    }

    return null;
  }
}
