import React from 'react';
import { path } from 'ramda';

import {
  FormatHelper,
  TRANSLATION_KEY_FORMAT,
  TRANSLATION_REGEX_FORMAT,
  TRANSLATION_VALUE_FORMAT
} from './FormatHelper';

import { MDFCore } from './MDFCore';
import { ComponentManager } from './ComponentManager';
import { runScript } from '../rowan/rowan';
import { SassHelper } from './SassHelper';

// Regex for model value
export const MODEL_VALUE_FORMAT = /::([a-zA-Z0-9.-_]*)/;

// Regex for variable value
export const SCCS_VALUE_FORMAT = /#\$([a-zA-Z0-9.-_]*)/;

export class ModelHelper {
  // Convert a data reference to the actual data by using the data reference (modelPath)
  // to traverse the model object and return the value that was found.
  // The name parameter is the view name, which was passed in for logging.
  private static resolvePath(modelPath: string, model: any, _name?: string): any {
    // Model references start with '::' and then use normal JavaScript object notation.
    // Remove the '::' and then split the rest on '.' to get the references at each level.
    const pathParts = modelPath.substring(2).split('.');
    return path(pathParts, model);
  }

  // Resolve a translation reference to the value in the translations data.
  private static resolveTranslation(metaData: string, model: any, name?: string): any {
    let formattedText = metaData;

    // Test if the given metaData is in expected regex format
    if (TRANSLATION_REGEX_FORMAT.test(metaData)) {
      // Match the metaData which returns an array results
      const matchedData = metaData.match(TRANSLATION_REGEX_FORMAT);
      formattedText = `@@${matchedData[1]}`;
      let values = null;

      // Check if the result has valid entries
      if (matchedData.length === 4 && matchedData[2] && matchedData[3]) {
        // Array item 3 holds the parameter data. Split it and parse the data
        const valuesParts = matchedData[3].split(',');

        // Looping each parameters
        valuesParts.forEach((value, index) => {
          // If the param value is in expected format (::<Name>), then parse it and extract the
          if (TRANSLATION_VALUE_FORMAT.test(value)) {
            // Match the param value to extract required characters
            const modelParts = value.match(TRANSLATION_VALUE_FORMAT);
            // Extract the key for the value
            const modelKey = value.match(TRANSLATION_KEY_FORMAT);

            if ((modelParts.length === 2) && modelParts[0] && modelKey[0]) {
              // Get the actual model value
              const modelValue = ModelHelper.resolvePath(modelParts[0], model, name);

              // Replace the value in the actual metaData string if the value is not null
              if ((modelValue !== undefined) && (modelValue !== null)) {
                values = values || {};

                // If key exists, then construct the object with key and value
                if (modelKey[1] !== '') {
                  values[modelKey[1]] = modelValue;
                }
                else {
                  // Fallback to default legacy placeholders
                  values[`${index}`] = modelValue;
                }
              }
            }
          }
          else {
            values = JSON.parse(matchedData[3]);
          }
        });
      }

      formattedText = FormatHelper.formatMessage(formattedText, values);
    }

    return formattedText;
  }

  // Resolve Scss variable reference to the value in the Scss variables data.
  private static resolveScss(metaData: string, model: any): any {
    if (SCCS_VALUE_FORMAT.test(metaData)) {
      // If the metaData contains data to bind, resolve the binding by finding all matches.
      if (metaData.lastIndexOf('#$') === 0) {
        return ModelHelper.resolveScssVariable(metaData, model);
      }

      return metaData;
    }
  }

  private static resolveScssVariable(modelPath: string, model: any): any {
    // Remove the '#$' from variable and then remove '-' to check the variable values exported in _varibles.scss.
    const pathParts = modelPath.substring(2).replace(new RegExp('-', 'gm'), '');
    const value = path([pathParts], model);

    if (!value) {
      return SassHelper.getVariable(pathParts);
    }

    return value;
  }

  // Traverse the provided view and return an array of data reference names (without the ::).
  static findProps(metaData: any) {
    const result = [];

    if (!metaData) {
      return result;
    }
    else if ((typeof metaData === 'string') && MODEL_VALUE_FORMAT.test(metaData)) {
      // When metaData has multiple bindings within a string, extract model data for each bindings
      const regEx = new RegExp(MODEL_VALUE_FORMAT, 'g');
      let matches;

      // TODO: Don't do assignment here
      // tslint:disable-next-line:no-conditional-assignment
      while ((matches = regEx.exec(metaData)) != null) {
        result.push(matches[0].substring(2));
      }
    }
    else if (Array.isArray(metaData)) {
      metaData.map((item) => Array.prototype.push.apply(result, ModelHelper.findProps(item)));
    }
    else if (typeof metaData === 'object') {
      Object.keys(metaData).forEach((key) => {
        Array.prototype.push.apply(result, ModelHelper.findProps(metaData[key]));
      });
    }

    return result;
  }

  // Try to detect if the array in question is a Rowan script
  static isRowan(metaData: string | any[]) {
    if (Array.isArray(metaData) && metaData.length > 0) {
      if (typeof metaData[0] === 'string') {
        // The first value in the array needs to be a function reference - a string that starts with ':' (and not '::")
        return (metaData[0].startsWith(':') && !metaData[0].includes('::'));
      }
      else if (Array.isArray(metaData[0])) {
        // The first value might be a nested function definition, so dig deeper.
        return (ModelHelper.isRowan(metaData[0]));
      }
      else {
        // If it's not a string or another array, it's not Rowan
        return false;
      }
    }
    else {
      // The array is empty, so it's not a Rowan script
      return false;
    }
  }

  // Resolve all the data (model) references in the view (metaData) and return the updated view.
  // The name parameter is the view's name and is only provided to facilitate error logging.
  static resolve(metaData: any, model: any, name?: string): any {
    if (!model || !metaData) {
      return metaData;
    }

    if ((typeof metaData === 'string') && metaData === '::MODEL::') {
      // To pass all the props from parent to child instead of passing one prop by prop
      // Using syntax '::MODEL::', this is similar to passing {this.props} in React components
      return model;
    }
    else if ((typeof metaData === 'string') && metaData.startsWith('@@')) {
      return ModelHelper.resolveTranslation(metaData, model, name);
    }
    else if ((typeof metaData === 'string') && metaData.startsWith('#$')) {
      return ModelHelper.resolveScss(metaData, model);
    }
    else if ((typeof metaData === 'string') && metaData.startsWith('##')) {
      // Gives ability to pass view/component as property of another view
      const viewVariable = metaData.substring(2);
      const viewName = ModelHelper.resolve(viewVariable, model);
      return ComponentManager.getComponent(viewName);
    }
    else if ((typeof metaData === 'string') && metaData.startsWith('#<')) {
      // Returns an instance of the view rather than the view itself, passing the enclosing view's properties
      const viewVariable = metaData.substring(2);
      const viewName = ModelHelper.resolve(viewVariable, model);
      return MDFCore.createView(ComponentManager.getComponent(viewName), model);
    }
    else if ((typeof metaData === 'string') && MODEL_VALUE_FORMAT.test(metaData)) {
      // If the metaData contains data to bind, resolve the binding by finding all matches and its data from model
      // if metaData has only single binding
      if (metaData.lastIndexOf('::') === 0) {
        return ModelHelper.resolvePath(metaData, model, name);
      }

      // When metaData has multiple bindings within a string, extract model data for each bindings
      const regEx = new RegExp(MODEL_VALUE_FORMAT, 'g');
      let matches;
      const resolvedValues = {};

      // TODO: Don't do assignment here
      // tslint:disable-next-line:no-conditional-assignment
      while ((matches = regEx.exec(metaData)) != null) {
        resolvedValues[matches[0]] = ModelHelper.resolvePath(matches[0], model, name);
      }

      // replace the resolved values in metaData
      Object.keys(resolvedValues).forEach((key) => {
        metaData = metaData.replace(key, resolvedValues[key]);
      });

      return metaData;
    }
    else if (Array.isArray(metaData)) {
      // If the array is a Rowan script, run it and return the value. Otherwise, just process the array.
      // Move the model data under a single attribute to avoid errors from Ramda when the state is large.
      if (ModelHelper.isRowan(metaData)) {
        return runScript(metaData, { model });
      }
      else {
        return metaData.map((item) => ModelHelper.resolve(item, model, name));
      }
    }
    else if (React.isValidElement(metaData)) {
      // If the metaData is a React element, just return it rather than interpreting it as an object.
      return metaData;
    }
    else if (typeof metaData === 'object') {
      const resolved: any = {};
      // Keys with these names are always removed from the Rowan context as they are known to be
      // added by the ag-grid and popper and very likely contain cycles.
      const keysAlwaysSkipped = [
        'agGridReact',
        'api',
        'columnApi',
        'defaultGridApi',
        'frameworkComponentWrapper',
        'gridApi',
        'gridOptionsWrapper',
        'popper'
      ];

      Object.keys(metaData).forEach((key) => {
        if (key === '...') {
          const objectToMerge = ModelHelper.resolve(metaData[key], model, name);
          Object.assign(resolved, objectToMerge);
        }
        else if (keysAlwaysSkipped.includes(key)) {
          // Do nothing - these are known keys that should not be resolved.
          resolved[key] = metaData[key];
        }
        else if (metaData[key] instanceof Element) {
          // Do nothing - DOM nodes should not be resolved.
          resolved[key] = metaData[key];
        }
        else if (typeof metaData[key] === 'object' && (metaData[key]?.hasOwnProperty('WrappedComponent') || metaData[key]?.hasOwnProperty('displayName'))) {
          // Do nothing - Components should not be resolved.
          resolved[key] = metaData[key];
        }
        else if (((key === 'beans') || (key === 'column') || (key === 'node')) && typeof metaData[key] === 'object') {
          if (metaData[key]?.gridApi || metaData[key]?.beans || metaData[key]?.columnUtils) {
            // Do nothing - this is an ag-grid column key instead of an application column key.
            resolved[key] = metaData[key];
          }
          else {
            // Resolve the value, trusting that it doesn't contain a cycle.
            resolved[key] = ModelHelper.resolve(metaData[key], model, name);
          }
        }
        else {
          resolved[key] = ModelHelper.resolve(metaData[key], model, name);
        }
      });

      return resolved;
    }
    else {
      return metaData;
    }
  }
}
