import { ModelHelper } from '../core/ModelHelper';
import { RenderHelper } from '../core/RenderHelper';

// type Iterator needs to be specially handled
// Special attributes:
//  items: a model reference to the array that will iterated
//  itemVar: the name under which the item under iteration and the item index is exposed to the content of the Iterator
//    defaults to 'item' and 'itemIndex' when not provided.
export default function iteratorRenderer({ viewName, view, model }) {
  const items = ModelHelper.resolve(view.items, model, viewName);
  const itemContent = view.content;
  const itemVar: string = view.itemVar || 'item';

  return items && items.map((item: any, itemIndex: number) => {
    const itemModel = {
      ...model,
      [itemVar]: item,
      [itemVar + 'Index']: itemIndex
    };

    return RenderHelper.renderContent(itemContent, itemModel);
  });
}
