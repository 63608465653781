import React from 'react';
import DOM from 'react-dom-factories';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import { DOMHelper } from './DOMHelper';
import { MDFCore } from './MDFCore';
import { ModelHelper } from './ModelHelper';
import { ReduxHelper } from './ReduxHelper';
import { RendererManager } from './RendererManager';
import createReactComponentRenderer from '../renderers/createReactComponentRenderer';
import { RenderHelper } from './RenderHelper';

class ComponentRegistry {
  private static registry: any = {};

  static isComponentRegistered(name: string) {
    return !!ComponentRegistry.registry[name];
  }

  static getComponent(name: string) {
    return ComponentRegistry.registry[name];
  }

  static setComponent(name: string, component: any) {
    ComponentRegistry.registry[name] = component;
  }

  static getRegistry(): any {
    // Return a copy of the registry, primarily to aid unit testing
    // that would check the before and after state of the registry.
    return cloneDeep(ComponentRegistry.registry);
  }
}

export class ComponentManager {
  static getRegistry(): any {
    // Return a copy of the registry, primarily to aid unit testing
    // that would check the before and after state of the registry.
    return ComponentRegistry.getRegistry();
  }

  static isComponentRegistered(name: string) {
    return ComponentRegistry.isComponentRegistered(name);
  }

  static getComponent(name: string) {
    return ComponentRegistry.getComponent(name);
  }

  static safelyRegisterComponent(name: string, component: any) {
    if (!ComponentRegistry.isComponentRegistered(name)) {
      ComponentRegistry.setComponent(name, component);
      RendererManager.registerRenderer(name, createReactComponentRenderer(component));
      return this.getComponent(name);
    }
    else {
      console.info(`ComponentManager.safelyRegisterComponent: Component [${name}] has already been registered. This registration will be ignored`);
    }
  }

  static registerComponent(name: string, component: any) {
    if (ComponentRegistry.isComponentRegistered(name) && ComponentRegistry.getComponent(name) !== component) {
      console.info(`ComponentManager.registerComponent: Component [${name}] is being replaced.`);
    }

    ComponentRegistry.setComponent(name, component);
    RendererManager.registerRenderer(name, createReactComponentRenderer(component));
    return this.getComponent(name);
  }
}

export class ViewManager {
  private static viewPath = '/dist/views';

  // Convert a view object into a React component.
  private static createViewComponent(viewName: string, viewJson: any) {
    // Find all the data references in the view (::propertyName) and
    // collect them into an object so we can reference them later.
    const viewPropsList = ModelHelper.findProps(viewJson);
    const viewPropsHash = {};
    viewPropsList.forEach((item) => viewPropsHash[item] = true);

    // Make a React component out of the view. The RenderHelper will be
    // called from the render() method to traverse view object and render the view.
    let ViewComponent: any = class extends React.Component<any, any> {
      renderStart;
      shouldLog = false;
      private firstRender = true;
      private viewRef: React.RefObject<React.ReactInstance> = React.createRef();

      constructor(props: any) {
        super(props);
      }

      viewProps = viewPropsHash;

      transferProps = (view: any) => {
        // If the view is not a DOM node, transfer any properties in this.props
        // that are not picked up with ::propName references so that properties
        // injected by various components in the tree get passed down to their children.
        if (!DOM[view.type]) {
          // Make sure there is a properties object
          view.properties = view.properties || {};

          Object.keys(this.props).forEach((key) => {
            // Only transfer items from this.props that have a value
            // For example, the className property may get passed in from React as a "standard"
            // property, but with an empty string as the value. We don't want to overwrite
            // the className property in view.properties if it was given a value by the app.
            if (!this.viewProps[key] && this.props[key]) {
              view.properties[key] = this.props[key];
            }
          });
        }

        return view;
      };

      componentDidMount() {
        if (this.shouldLog) {
          console.warn(`${viewName}.componentDidMount():`);
          console.log(`${viewName} = `, viewJson);
          console.log(`${viewName}.props = `, this.props);
          console.log(`${viewName}.viewRef = `, this.viewRef.current);
          console.log(`Mount time = ${Date.now() - this.renderStart}ms`);
        }

        if (this.props.needsFocus && this.viewRef.current) {
          const element: HTMLElement = this.viewRef.current as HTMLElement;
          element.tabIndex = -1;
          DOMHelper.moveFocusTo(element);
        }

        this.props.onMount?.(viewName);
      }

      componentWillUnmount() {
        if (this.shouldLog) {
          console.warn(`${viewName}.componentWillUnmount():`);
          console.log(`${viewName} = `, viewJson);
          console.log(`${viewName}.props = `, this.props);
        }

        this.props.onUnmount?.(viewName);
      }

      componentDidUpdate() {
        if (this.shouldLog) {
          console.warn(`${viewName}.componentDidUpdate():`);
          console.log(`${viewName} = `, viewJson);
          console.log(`${viewName}.props = `, this.props);
          console.log(`${viewName}.firstRender = `, this.firstRender);
        }

        this.firstRender = false;
        this.props.onUpdate?.(viewName);
      }

      shouldComponentUpdate(nextProps: Readonly<any>, nextState: Readonly<any>, nextContext: any): boolean {
        let shouldUpdate = true;

        if (this.shouldLog) {
          console.warn(`${viewName}.shouldComponentUpdate():`);
          console.log(`${viewName} = `, viewJson);
          console.log('nextProps =', nextProps);
          console.log('nextState =', nextState);
          console.log('nextContext =', nextContext);
        }

        Object.keys(nextProps).forEach((key) => {
          if (nextProps[key] !== this.props[key]) {
            if (this.shouldLog) {
              console.warn(`${key} changed. Old value, new value`, this.props[key], nextProps[key]);
            }

            shouldUpdate = true;
          }
        });

        return shouldUpdate;
      }

      render() {
        this.renderStart = Date.now();
        this.shouldLog = MDFCore.shouldLog();

        if (viewJson?.properties?.hasOwnProperty('debug')) {
          this.shouldLog = ModelHelper.resolve(viewJson.properties.debug, this.props, viewName);
        }

        const viewJsonClone = this.transferProps(JSON.parse(JSON.stringify(viewJson)));

        // Optional view Name is passed only for error logging
        if (this.shouldLog) {
          console.warn(`${viewName}.render():`);
          console.log(`${viewName} = `, viewJsonClone);
          console.log(`${viewName}.props = `, this.props);
        }

        return RenderHelper.renderView(viewJsonClone, { ...this.props, ref: this.viewRef }, viewName);
      }
    };

    // If we have a Redux connector registered for the view, then
    // call Redux's connect() method to make the view Redux-aware.
    const reduxConnector = ReduxHelper.getConnector(viewName);

    if (reduxConnector) {
      const { mapStateToModel, mapDispatchToModel } = reduxConnector;
      ViewComponent = connect(mapStateToModel, mapDispatchToModel, null, { forwardRef: true })(ViewComponent);
    }

    // Add displayName to help with debugging.
    ViewComponent.displayName = viewName;
    return ViewComponent;
  }

  // A shortcut function to convert a view into a React component and add it to the ComponentManager.
  public static registerViewComponent(name: string, viewJson: any) {
    if (ComponentRegistry.isComponentRegistered(name)) {
      console.info(`ViewManager.registerViewComponent(): View [${name}] is being replaced.`);
    }

    const viewComponent = ViewManager.createViewComponent(name, viewJson);
    ComponentRegistry.setComponent(name, viewComponent);
    RendererManager.registerRenderer(name, createReactComponentRenderer(viewComponent));
  }

  // Find all the referenced views that are not registered and return their names as an array
  private static findChildViews(view: any, results: string[] = []): string[] {
    if (view.content) {
      const content = Array.isArray(view.content) ? view.content : [view.content];

      content.forEach((child: any) => {
        if (typeof child === 'object') {
          // If we find a 'type' that is not already a registered component or renderer, we assume it is a view that needs to be fetched
          if (!ComponentManager.isComponentRegistered(child.type) && !RendererManager.isRendererRegistered(child.type)) {
            results.push(child.type);
          }

          ViewManager.findChildViews(child, results);
        }
      });
    }

    return results;
  }

  // Set the path that will be used to find views
  static setViewPath(path: string) {
    ViewManager.viewPath = path;
  }

  // Returns a view.
  // The view will either be found in the ComponentManager or will return undefined.
  // This function replaces getView(), which anticipated downloading views from the
  // network, but that never happened.
  static getViewFast(name: string) {
    return ComponentManager.getComponent(name);
  }

  // Returns a Promise for a view.
  // The view will either be found in the ComponentManager or downloaded from some web server.
  // Once the view is found, the promise will be resolved with the view.
  // If the view is not in the ComponentManager and can't be downloaded, the promise will be rejected.
  static getView(name: string) {
    if (ComponentManager.isComponentRegistered(name)) {
      return new Promise((resolve) => {
        resolve(ComponentManager.getComponent(name));
      });
    }
    else {
      return new Promise((resolve, reject) => {
        fetch(ViewManager.viewPath + '/' + name + '.json')
          .then((response: Response) => {
            // fetch resolves the promise even if the response is 404.
            // If we have a good response return it so the next .then()
            // can process it, otherwise reject the promise.
            if (response.ok) {
              return response.json();
            }
            else {
              return Promise.reject(`ViewManager.getView: View/Component ${name} does not exist.`);
            }
          })
          .then(function(viewJson: any) {
            // Register the view, which fetch() has converted to a real JavaScript object
            ViewManager.registerViewComponent(name, viewJson);

            // Find all the views referenced by this view and load them, waiting for all
            // of them to load. Once they are all loaded, return the view component as the
            // value of the original Promise.
            Promise
              .all(ViewManager.findChildViews(viewJson).map(ViewManager.getView))
              .then(() => resolve(ComponentManager.getComponent(name)))
              .catch((e) => {
                console.error(e);
                reject(`ViewManager.getView: error loading child views for view ${name}.`);
              });
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  }

  // Take an object with keys that are view names and values that are views
  // and register them all as a "bundle".
  public static registerViewBundle(viewBundle: any) {
    if (viewBundle) {
      Object.keys(viewBundle).forEach((key) => {
        ViewManager.registerViewComponent(key, viewBundle[key]);
      });
    }
  }
}
