import dayjs, { Dayjs } from 'dayjs';
import { FormatHelper } from './FormatHelper';

import MDF_LOCALE_DATA from '../localization/translation.json';

// Need en-ca, en-gb, es-us, fr-ca in order to do the localizations we need.
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import 'dayjs/locale/fr-ca';

// Day.js plugins used in WFN
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import businessDaysPlugin from './BusinessDays';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import durationPlugin from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import localeDataPlugin from 'dayjs/plugin/localeData';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import objectSupportPlugin from 'dayjs/plugin/objectSupport';
import quarterOfYearPlugin from 'dayjs/plugin/quarterOfYear';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import weekdayPlugin from 'dayjs/plugin/weekday';

dayjs.extend(advancedFormatPlugin);
dayjs.extend(businessDaysPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(localeDataPlugin);
dayjs.extend(localizedFormatPlugin);
dayjs.extend(objectSupportPlugin);
dayjs.extend(quarterOfYearPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);
dayjs.extend(weekdayPlugin);

export class LocaleHelper {
  private static userLocale = 'en-us';
  private static localeData = MDF_LOCALE_DATA;
  private static defaultLocale = 'en-us';
  private static countryCode = 'us';
  private static cachedTranslations = [];

  // To get the user's locale
  static getUserLocale() {
    return LocaleHelper.userLocale;
  }

  static getCountryCode() {
    return LocaleHelper.countryCode;
  }

  private static normalizedLocaleData(localeData: any): any {
    const normalizedLocaleData = Object.assign({}, LocaleHelper.localeData);

    Object.keys(localeData).forEach((key) => {
      const normalizedKey = key.toLowerCase();

      if (LocaleHelper.localeData[normalizedKey]) {
        normalizedLocaleData[normalizedKey] = Object.assign(LocaleHelper.localeData[normalizedKey], localeData[key]);
      }
      else {
        normalizedLocaleData[normalizedKey] = localeData[key];
      }
    });

    return normalizedLocaleData;
  }

  // To set the locale data store
  static setLocaleData(data: any) {
    LocaleHelper.localeData = this.normalizedLocaleData(data);
  }

  // Initialize the user's locale. Call this from the application's controller.
  // Since this has to be async (due to appShell.getLocale() being async), calling it inside
  // some other function can cause async/await to go a bit viral in your application.
  // You can break that chain by doing:
  //   LocaleHelper.initializeLocale().then(() => 0);
  // This will let your code continue and avoid having the enclosing function need to be async.
  static async initializeLocale(value?: string, defaultLocale?: string, defaultCountryCode?: string) {
    // We have 4 ways to figure out the user's locale if the locale isn't provided above:
    // 1. The WFN Shell's getLocale() function
    // 2. The standard appShell's getLocale() function (which is async)
    // 3. The ADPLangLocaleCookie (which be handled by the first two, but just in case)
    // 4. The browser's language setting (either via navigator.languages or navigator.language)
    // 5. Default to en-us
    let cookieLocale;

    const localeMatches = document?.cookie.match(/ADPLangLocaleCookie=([^;]+)/);

    if (localeMatches && localeMatches[1]) {
      cookieLocale = localeMatches[1].replace('_', '-').toLowerCase();
    }

    const locale = value ??
      window?.['WFNShell']?.getLocale() ??
      (await window?.['getShell']?.().getLocale()) ??
      cookieLocale ??
      navigator?.languages?.[0] ?? navigator?.language ??
      'en-us';

    this.setLocale(locale, defaultLocale, defaultCountryCode);

    // Listen for changes to the locale
    window?.['SynergConfig']?.watchLocale((newLocale) => {
      let updatedLocale = newLocale.replace('_', '-').toLowerCase();

      // Fix Waypoint locales that are incomplete
      if (!updatedLocale.includes('-')) {
        switch (updatedLocale) {
          case 'en':
          case 'es':
            updatedLocale = updatedLocale + '-us';
            break;

          case 'fr':
            updatedLocale = updatedLocale + '-ca';
            break;

          default:
            console.error(`Locale ${updatedLocale} received from Waypoint is not a supported locale.`);
        }
      }

      LocaleHelper.setLocale(updatedLocale);
    });
  }

  // To set the locale data store
  static setLocale(value: string, defaultLocale?: string, defaultCountryCode?: string) {
    LocaleHelper.userLocale = value.toLowerCase();
    LocaleHelper.defaultLocale = defaultLocale || LocaleHelper.defaultLocale;
    LocaleHelper.countryCode = defaultCountryCode?.toLowerCase() || LocaleHelper.userLocale.split('-')[1];
    FormatHelper.updateCultureFormats(LocaleHelper.countryCode);
    dayjs.locale(value.toLowerCase());

    // Update Waypoint with the new locale
    const SynergConfig = (window as any).SynergConfig;
    const waypointLocale = SynergConfig?.getLocale().toLowerCase();

    if (waypointLocale !== LocaleHelper.userLocale) {
      SynergConfig?.setLocale(LocaleHelper.userLocale);
    }

    console.log(`LocaleHelper.setLocale(): User's locale is now ${LocaleHelper.userLocale}`);
  }

  // This method downloads the given translation URL and appends it in the localeData. If the given translation URL already available in localeData, then it skips downloading the content.
  static loadLocaleData(url: string) {
    // If the given url is already cached, then don't load the file again.
    if (LocaleHelper.cachedTranslations.includes(url)) {
      return Promise.resolve({});
    }

    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response: Response) => {
          if (response.ok) { // fetch resolves the promise even if the response is 404
            return response.json();
          }
          else {
            return Promise.reject(`LocaleHelper.setLocaleDataFromUrl: Locale ${name} does not exist.`);
          }
        })
        .then((localeJson: any) => {
          const currentLocaleData = LocaleHelper.localeData;
          const normalizedLocaleJson = this.normalizedLocaleData(localeJson);
          LocaleHelper.localeData = Object.assign(currentLocaleData, normalizedLocaleJson);

          // Add the url as cached
          LocaleHelper.cachedTranslations.push(url);
          resolve(normalizedLocaleJson);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  // To get the message from IDS key
  static getMessage(key: string, locale = LocaleHelper.userLocale): string {
    // Try user's locale, fallback to default locale
    if (LocaleHelper.localeData) {
      const usersLocaleMessage = LocaleHelper.localeData[locale];
      const defaultLocaleMessage = LocaleHelper.localeData[LocaleHelper.defaultLocale];
      return (usersLocaleMessage && usersLocaleMessage[key] || defaultLocaleMessage[key]);
    }
    else {
      console.error('Please load translations using LocaleHelper.setLocaleData()!');
    }
  }

  // To manage the global dayjs (was moment) object.
  // Deprecated: use LocaleHelper.dateAndTime getter instead as it doesn't result
  // in LocaleHelper.getMoment()(), which can look a bit strange.
  static getMoment() {
    return dayjs;
  }

  // Returns a handle to the common date and time helper API (dayjs)
  static get dateAndTime() {
    return dayjs;
  }

  static isDate(obj: any): boolean {
    // Convert obj to a boolean value before comparing so that the function always returns a boolean.
    return (!!obj) && obj instanceof Date;
  }

  static isDST(day?: Dayjs | Date): boolean {
    // Use the dayjs object passed in by the application or the default moment object
    const date: Dayjs = LocaleHelper.isDate(day) ? LocaleHelper.dateAndTime(day) : (day as Dayjs || LocaleHelper.dateAndTime());

    // Use the logic from moment's isDST() function by comparing the utcOffset of the provided date
    // with the utcOffset in month 0 (January - southern hemisphere) and 5 (June - northern hemisphere).
    return (date.utcOffset() > date.clone().month(0).utcOffset()) || (date.utcOffset() > date.clone().month(5).utcOffset());
  }
}
