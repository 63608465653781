export class FeatureFlags {
  private static flags = {
    mdfButton2: true,
    mdfFocusPane: true,
    waypointCards: true,
    waypointModal: true,
    waypointSlideIn: true,
    wfnHtml2Pdf: true
  };

  static hasFeature(featureName: string) {
    const flagValue = localStorage.getItem(featureName);

    if (flagValue === 'off') {
      this.disableFeature(featureName);
      return false;
    }
    else if (flagValue === 'on') {
      this.useFeature(featureName);
      return true;
    }
    else {
      return !!this.flags[featureName];
    }
  }

  static useFeature(featureName: string) {
    this.flags[featureName] = true;
    localStorage.setItem(featureName, 'on');
  }

  static disableFeature(featureName) {
    this.flags[featureName] = false;
    localStorage.removeItem(featureName);
  }
}
