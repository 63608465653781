import { ModelHelper } from '../core/ModelHelper';
import { RenderHelper } from '../core/RenderHelper';

export default function contentSelectorRenderer({ viewName, view, model }) {
  // Resolve the model properties of ViewSelector
  const viewProperties = ModelHelper.resolve(view.properties, model, viewName);

  // When selectOn property gets a number 0 it gets falsey and defaultSelector is being passed instead changed the condition to check with null.
  const selectOn = ((viewProperties.selectOn || (viewProperties.selectOn === 0)) ? viewProperties.selectOn : null) || viewProperties.defaultSelector;
  let contentList = view.content;
  let defaultContentIndex = -1;

  // Convert content that is a single object to an array
  if (!Array.isArray(view.content)) {
    contentList = [view.content];
  }

  // Get the index of the first content item that matches the 'when' condition
  let contentIndex = contentList.findIndex((currentContent, currentIndex) => {
    let contentWhen = null;

    // get the first default content index
    if ((!currentContent.hasOwnProperty('when') || (currentContent.when === null) || (currentContent.when === undefined)) && defaultContentIndex === -1) {
      defaultContentIndex = currentIndex;
    }

    // Resolve the model property of currentContent.when
    if ((typeof currentContent.when === 'string') && (currentContent.when.lastIndexOf('::') === 0)) {
      contentWhen = ModelHelper.resolve(currentContent, model, viewName);
    }

    if ((typeof currentContent.when === 'string') || (typeof currentContent.when === 'number') || contentWhen) {
      if (contentWhen && contentWhen.hasOwnProperty('when')) {
        currentContent.when = [contentWhen.when];
      }
      else {
        currentContent.when = [currentContent.when];
      }
    }
    else if (!Array.isArray(currentContent.when)) {
      return false;
    }

    return (currentContent.when.indexOf(selectOn) > -1);
  });

  contentIndex = (contentIndex > -1) ? contentIndex : defaultContentIndex;

  if (contentIndex > -1) {
    return RenderHelper.renderContent(contentList[contentIndex], model);
  }
}
