const CALLBACK_NAME = '__lazyscriptloader';
// default timeout to 5 seconds
const SCRIPT_TIMEOUT = 5000;

interface IAsyncScriptLoader {
  url: string;
  name?: string;
  timeout?: number;
  callbackName?: string;
}

export class AsyncScriptLoader {
  static initialize(options: IAsyncScriptLoader): Promise<any> {
    // reject the request if not running inside a browser.
    if (typeof window === 'undefined') {
      return Promise.reject(new Error('Can only load in the browser'));
    }

    // reject the request if url is not found
    if (!options.url) {
      return Promise.reject(new Error('url is required for scriptloader'));
    }

    return new Promise(function(resolve, reject) {
      // set timeout to reject the promise after a timeout.
      const timeoutId = setTimeout(function() {

        // remove the empty internal callback.
        delete window[CALLBACK_NAME];

        // Check if script object is loaded in window object, then resolve
        if (window[options.name]) {
          // resolve as script is loaded
          resolve(window[options.name]);
        }
        else {
          // reject as we did not received the window object as well as reached timeout
          reject(new Error('Could not load script, timeout reached'));
        }
      }, options.timeout || SCRIPT_TIMEOUT);

      // Prepare the `script` tag to be inserted into the page.
      const scriptElement = document.createElement('script');
      scriptElement.async = true;

      if (options.callbackName) {
        AsyncScriptLoader.registerCallback(scriptElement, options, timeoutId, resolve);
      }
      else {
        AsyncScriptLoader.registerOnLoad(scriptElement, options, timeoutId, resolve);
      }

      // Insert the `script` tag.
      document.body.appendChild(scriptElement);
    });
  }

  // This method register the callback method in the url. When the script executes the callback method, it resolve the function.
  // NOTE: Google maps require callback method in the url. When Google maps is ready, it will call the function specified using the callback parameter. See https://developers.google.com/maps/documentation/javascript/tutorial
  // NOTE: Do not use this callback function for general use
  static registerCallback(scriptElement, options, timeoutId, resolve) {
    // Set callback to internal method
    scriptElement.src = `${options.url}&callback=${CALLBACK_NAME}`;

    // Hook up the on load callback.
    window[CALLBACK_NAME] = function() {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      resolve(window[options.name]);
      delete window[CALLBACK_NAME];
    };
  }

  static registerOnLoad(scriptElement, options, timeoutId, resolve) {
    // Set script url
    scriptElement.src = `${options.url}`;

    // Hook up the on load callback.
    scriptElement.addEventListener('load', function(/* e */) {
      // clear the timeout
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      // resolve as script is loaded
      resolve(window[options.name]);
    }, false);
  }
}
