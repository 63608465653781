import React from 'react';

import { ModelHelper } from './ModelHelper';
import { ComponentManager } from './ComponentManager';
import { RendererManager } from './RendererManager';
import { runScript } from '../rowan/rowan';

export class RenderHelper {
  static renderView(view: any, model: any, viewName?: string) {
    const properties = view.properties;

    // Resolve the render property to determine if this node needs to be rendered
    if (properties?.hasOwnProperty('render')) {
      const shouldRender = ModelHelper.resolve(properties.render, model, viewName);

      if (shouldRender === false) {
        return null;
      }
    }

    // Resolve the ready property to determine if we need to show a busy indicator
    // instead of the actually rendering this node
    if (properties?.hasOwnProperty('ready')) {
      const isReady = ModelHelper.resolve(properties.ready, model, viewName);

      if (isReady === false) {
        const busyProps = ModelHelper.resolve(properties.busyProperties, model, viewName);
        return this.renderBusyIndicator(busyProps);
      }
    }

    // Save the original view.type value for logging later.
    const originalViewType = view.type;

    // View type could be a variable so resolving it, if view.type does not start with '::' resolver will return it as is.
    const viewType = ModelHelper.resolve(view.type, model);

    if (!viewType) {
      // If there's no view to render, then don't render anything.
      console.warn(`RenderHelper.renderView: While rendering view [${viewName}], type [${originalViewType}] resolved to [${viewType}]!`);
      return null;
    }

    // Look up the renderer for this node type
    const renderer = RendererManager.getRenderer(viewType);

    if (renderer) {
      // do not pass internal properties down (clone before modifications)
      const viewProperties = { ...(properties || {}) };
      delete viewProperties.render;
      delete viewProperties.ready;
      delete viewProperties.busyProperties;
      delete viewProperties.analyticsName;

      // We need to disable components that initiate data changes when in user impersonation modes.
      // This code checks with the WFNShell to see if we are in a user impersonation mode and then uses
      // a property named 'savesChanges' to mark that component as needing to be disabled during impersonation.
      const globalContextObject: any = window['WFNShell']?.globalContextObject;
      const isImpersonatingUser = !!globalContextObject?.getSessionContext()?.isUaImpersonateModeUser();
      const isPartnerPractitionerWithoutEditPrivilege = !!globalContextObject?.getSessionContext()?.isPartnerPractitionerSupport?.() && !globalContextObject?.isFeatureAvailable('78071600');

      if ((isImpersonatingUser || isPartnerPractitionerWithoutEditPrivilege) && viewProperties.hasOwnProperty('savesChanges')) {
        // Resolve the savesChanges property to determine if this component is disabled in View Site as User mode.
        const savesChanges = ModelHelper.resolve(viewProperties.savesChanges, model, viewName);

        // savesChanges needs to be exactly the boolean value true
        if (savesChanges === true) {
          console.log('RenderHelper.renderView(): View Site As User enabled - disabling component.');

          if (viewType === 'WfnVerticalWizard') {
            viewProperties['disable-done'] = true;
          }
          else if (viewType === 'MDFFocusPane' || viewType === 'SdfFocusPane') {
            viewProperties.disableAcceptButton = true;
          }
          else {
            viewProperties.disabled = true;
          }
        }
      }

      // Prevent rendering the savesChanges property in the DOM.
      delete viewProperties.savesChanges;

      // publicView is view with internal properties removed
      const publicView = { ...view, properties: viewProperties };

      return renderer({ viewName, view: publicView, model });
    }
    else {
      console.error(`RenderHelper.renderView: While rendering view [${viewName}], a renderer for view type [${viewType}] not found after resolving [${originalViewType}]!`);
    }
  }

  static renderContent(content: any, model: any, viewName?: string): any {
    // The content can either be an array of components (which might be strings), a single component, or just a scalar value.
    // If it's none of those types, this method returns undefined.
    if (Array.isArray(content)) {
      return content.map((child: any) => {
        if (ModelHelper.isRowan(child)) {
          // If it's a Rowan script, run it in the context of the model
          // Move the model data under a single attribute to avoid errors from Ramda when the state is large.
          return runScript(child, { model });
        }
        else {
          // If it's a content item, render each item passing in the same model.
          return RenderHelper.renderContent(child, model, viewName);
        }
      });
    }
    else if (typeof content === 'string') {
      // The content is a string, so just return the string (after model lookup)
      return ModelHelper.resolve(content, model, viewName);
    }
    else if (content && (typeof content === 'object')) {
      // The content exists and is a single object, so render that object and return it.
      return this.renderView(content, model, viewName);
    }
    else {
      // The content exists and is some scalar other than a string or object, so just return it.
      return content;
    }
  }

  static addEventsToProperties(properties: any, model: any, viewEvents: any[]) {
    viewEvents.forEach((viewEvent: any) => {
      properties[viewEvent.from] = (...eventArgs) => {
        const eventHandler = model[viewEvent.dispatch] || viewEvent.dispatch;

        if (typeof eventHandler === 'function') {
          if (viewEvent.params !== undefined) {
            if (Array.isArray(viewEvent.params)) {
              return eventHandler(...viewEvent.params, ...eventArgs);
            }
            else {
              return eventHandler(viewEvent.params, ...eventArgs);
            }
          }
          else {
            return eventHandler(...eventArgs);
          }
        }
        else {
          console.log(`The model has no function matching the name ${viewEvent.dispatch} for event ${viewEvent.from}`);
          return undefined;
        }
      };
    });
  }

  private static busyIndicatorContainerProps = {
    className: 'mdfBusyIndicatorContainer'
  };

  private static defaultBusyProps = {
    overlay: false,
    className: 'mdfDefaultBusyIconColor'
  };

  private static renderBusyIndicator(busyProps?: any) {
    let BusyIndicator = ComponentManager.getComponent('BusyIndicator');

    if (!BusyIndicator) {
      console.error('Please register the component named "BusyIndicator" from the ADP React Components!');
      BusyIndicator = <div/>;
    }

    busyProps = { ...this.defaultBusyProps, ...busyProps };
    return <div {...this.busyIndicatorContainerProps}><BusyIndicator {...busyProps}/></div>;
  }
}
