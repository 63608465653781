declare const Container: any;

interface IHeader {
  name: string;
  value: string;
}

interface IRESTRequest {
  baseURL: string;
  URI: string;
  method: string;
  body?: any;
  headers?: IHeader[];
  skipClearingSession?: boolean;
  staleOk?: boolean;
  cacheByPassContainer?: boolean;
}

export class NativeHelper {
  // Opens an internal or external page in a modal.
  static openPage(config: any) {
    if (Container && config.uri) {
      const openWebPageAction = {
        WebPage: {
          URI: config.uri,
          baseURL: config.baseURL,
          hideAlert: true,
          options: {
            documentFormat: config.documentType
          }
        },
        View: {
          controls: [
            {
              Button: {
                Command: {
                  Parameters: {
                    Action: {
                      CloseWebPageAction: {
                        id: ''
                      },
                      type: 'CloseWebPageAction'
                    }
                  },
                  class: 'Container.Action',
                  method: 'processAction'
                },
                id: 'back',
                label: '',
                placement: 'left'
              }
            },
            {
              Button: {
                id: 'share',
                label: '',
                placement: 'right',
                style: 'Share'
              }
            }
          ],
          modal: config.uri.toLowerCase().indexOf('.pdf') === -1,
          scaleContentToFit: true,
          hideNavigationBar: false,
          style: navigator.userAgent.toLowerCase().includes('android') ? 'sharesheet' : 'fullscreen',
          title: config.title,
          orientation: 'any'
        },
        callbackAfterDismissed: !!config.onDismiss
      };

      Container.Action.processAction(
        {
          OpenWebPageAction: openWebPageAction,
          type: 'OpenWebPageAction'
        },
        (response: any) => {
          config?.onDismiss?.(response);
        },
        false
      );
    }
  }

  // Opens a new page via SSO.
  static openPageWithSso(config: any) {
    Container?.CompanionApp.startExternalSSO(
      {
        clientId: config.clientId,
        redirect_uri: config.redirect_uri
      },
      'app',
      config.onSuccess,
      config.onError
    );
  }

  // get mobile AppContext object which has Mobile device information.
  static getAppContext(): Promise<any> {
    return new Promise((resolve, reject) => {
      Container.getAppContextWithCallback(
        (response: any) => {
          resolve(response.AppContext);
        },
        (response: any) => {
          reject(response);
        }
      );
    });
  }

  // make rest call via Corodova brige.
  static fetch(url: string, config: RequestInit) {
    let baseURL = '';
    const headers = [];

    if (!url.startsWith('http')) {
      baseURL = `${location.protocol}//${location.host}`;
    }

    if (config.headers) {
      Object.keys(config.headers).forEach((name) => headers.push({ name, value: config.headers[name] }));
    }

    return NativeHelper.makeRestCall({
      baseURL,
      URI: url,
      method: config.method || 'GET',
      body: config.body,
      headers
    });
  }

  // store data in native memeory store like key value pair.
  static addCache(key, data, timeout, successCallback, failureCallback) {
    Container.MemoryStore.put(key, data, timeout, successCallback, failureCallback);
  }

  // get stored data from native memory store with a key.
  static getCache(key, successCallback, failureCallback) {
    Container.MemoryStore.get(key, successCallback, failureCallback);
  }

  private static makeRestCall(request: IRESTRequest): Promise<any> {
    if (request.URI.startsWith('http') && !request.baseURL) {
      // split the url to find the basename
      const splitIndex = request.URI.indexOf('/', 8);

      request.baseURL = request.URI.substr(0, splitIndex);
      request.URI = request.URI.substr(splitIndex);
    }

    const restRequest = {
      RESTRequest: { ...request }
    };

    restRequest.RESTRequest.method = request.method.toUpperCase();

    if (!restRequest.RESTRequest.body) {
      restRequest.RESTRequest.body = undefined;
    }

    restRequest.RESTRequest.cacheByPassContainer = true;
    restRequest.RESTRequest.staleOk = false;

    // create a new promise to invoke native container method
    return new Promise((resolve, reject) => {
      Container.REST.invoke(restRequest,
        (response) => {
          const res = response.RESTResponse;

          if (res && (res.status >= 200 && res.status < 300)) {
            return resolve(res);
          }

          if (res && res.status === 401) {
            return reject({ status: 401 });
          }

          return reject();
        },
        (response) => {
          const res = response.RESTResponse;

          if (res && res.status === 401) {
            // if session is invalidated by WFN
            return reject({ status: 401 });
          }

          // get the server requestid from the response
          let requestId = null;

          if (res && res.headers) {
            requestId = res.headers.find((e) => e.name === 'x-request-id');
          }

          return reject({
            status: res.status,
            error: response,
            requestId: requestId ? requestId.value : res.status
          });
        });
    });
  }
}
