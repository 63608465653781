interface IADPElement extends HTMLElement {
  setFocus?: () => {};
}

export class DOMHelper {
  public static moveFocusTo(node: string | HTMLElement) {
    // Put this in a setTimeout to move this to next 'tick' and allow React
    // to finish re-rendering the DOM before looking for nodes to put focus on.
    setTimeout(() => {
      const focusNode: IADPElement = typeof node === 'string' ? document.querySelector(`#${node}`) : node as IADPElement;

      if (focusNode) {
        if (focusNode.tagName.includes('-')) {
          // Our web components that take focus should implement a setFocus function
          focusNode.setFocus?.();
        }
        else {
          // Otherwise, call the HTMLElement focus method.
          focusNode.focus();
        }
      }
      else {
        console.warn('DOMHelper.moveFocusTo(): Could not find node ', node);
      }
    }, 1);
  }

  /**
   * Utility to download server response parallel to state updates
   * @param response server response
   * @param fileName file name string
   * @param contentType content type string
   */
  public static downloadFileAfterFetch(response: any, fileName: string, contentType?: string) {
    const newBlob = new Blob([response], { type: contentType });
    const url = window.URL.createObjectURL(newBlob);

    const downloadAnchor = document.createElement('a');
    downloadAnchor.href = url;
    downloadAnchor.download = fileName;

    downloadAnchor.click();
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
    downloadAnchor.remove();
  }
}
