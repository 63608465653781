import { cloneDeep } from 'lodash';

// RendererManager holds a mapping of names to renderers. A renderer is a function with the
// following signature:
//    ({ viewName, view, model }) => React component
// A renderer knows how to render JSON nodes of a given type. Most renderers simply delegate to
// React.createElement which is implemented in the reactComponentRenderer renderer. Other renderers
// do some special manipulation of the input view JSON node before passing on the job to reactComponentRenderer
export class RendererManager {
  private static rendererRegistry: any = {};

  static getRegistry(): any {
    // Return a copy of the registry, primarily to aid unit testing
    // that would check the before and after state of the registry,
    // but also so mdf-editor can offer renderers on its palette.
    return cloneDeep(this.rendererRegistry);
  }

  static isRendererRegistered(name: string) {
    return !!this.rendererRegistry[name];
  }

  static getRenderer(name: string) {
    if (this.isRendererRegistered(name)) {
      return this.rendererRegistry[name];
    }
    else {
      console.info(`RendererManager.getRenderer(): There is no renderer for view [${name}].`);
    }
  }

  static registerRenderer(name: string, renderer: any) {
    if (this.rendererRegistry[name]) {
      console.info(`RendererManager.registerRenderer(): The renderer for view [${name}] is being replaced.`);
    }

    this.rendererRegistry[name] = renderer;
  }
}
