import React, { useEffect, useMemo, useRef } from 'react';
import { Provider } from 'react-redux';
import { ViewManager } from '../core/ComponentManager';
import { ReduxHelper } from '../core/ReduxHelper';
import { LocaleProvider } from './LocaleProvider';

const mutationCallback: MutationCallback = (mutationList, _observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === 'childList') {
      console.warn('mutationCallback(): A child node has been added or removed.', mutation);
    }
  }
};

export interface IApplicationProps {
  onMount?: (observer: MutationObserver) => void;
  onUnmount?: () => void;
  reducer?: (state: any, action: any) => any;
  rootViewName: string;
  store?: any;
}

const Application = (props: IApplicationProps) => {
  const shouldLog = !!(window?.localStorage?.getItem('mdfDebugAll'));
  const appNode = useRef(null);
  const observer = useRef<MutationObserver>(null);
  const { onMount, onUnmount, reducer, rootViewName, store } = props;

  const RootViewComponent = useMemo(() => ViewManager.getViewFast(rootViewName), [rootViewName]);

  const actualStore = useMemo(() => {
    if (store) {
      return store;
    }
    else if (reducer) {
      return ReduxHelper.createStore(reducer);
    }
    else {
      console.error('Application(): No store or reducer was provided.');
    }
  }, []);

  if (shouldLog) {
    console.warn('Application(): Entering. RootViewComponent =', RootViewComponent);
  }

  useEffect(() => {
    if (shouldLog) {
      console.warn('Application.useEffect(): Mounting. appNode =', appNode.current);

      // Create the observer once - useEffect can sometimes get called multiple times.
      if (!observer.current) {
        observer.current = new MutationObserver(mutationCallback);
      }
    }

    onMount?.(observer.current);

    return () => {
      if (shouldLog) {
        console.warn('Application.useEffect(): Unmounting. appNode =', appNode.current);
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      onUnmount?.();
    };
  }, []);

  return (
    <Provider store={actualStore}>
      <LocaleProvider>
        <RootViewComponent ref={appNode}/>
      </LocaleProvider>
    </Provider>
  );
};

export default Application;
