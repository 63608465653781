/* eslint @typescript-eslint/unbound-method: "off" */
import {
  compose,
  curry,
  is,
  join,
  map,
  partial,
  tap,
  unless
} from 'ramda';

import prettyFormat from 'pretty-format';

import { arrayify } from './arrays';

export const trace = (...args) => tap(partial(console.log, args));

export const makeGenericMessage = curry(
  (type, context, message, inline = true) => {
    const maybePretty = unless(is(String), prettyFormat);
    const breakValue = inline ? ' ' : '\n\n';
    const formatContext = compose(join(' > '), arrayify);
    const formatMessage = compose(join(breakValue), map(maybePretty), arrayify);

    return `${type} (${formatContext(context)}):${breakValue}${formatMessage(message)}`;
  }
);

export const makeError = makeGenericMessage('ERROR');
export const makeInfo = makeGenericMessage('INFO');
export const makeWarn = makeGenericMessage('WARNING');
