import waypointInput from '@waypoint/ui-framework/build/themes/one/json/context/input/synerg-text.json';
import waypointTokens from '@waypoint/ui-framework/build/themes/one/json/synerg-tokens.json';
import waypointUi from '@waypoint/ui-framework/build/themes/one/json/context/synerg-ui.json';

const variables = {
  white: waypointTokens.color.base.white.value,
  black: waypointTokens.color.base.black.value,

  // NEUTRALS (gray) (N1  N4) from lightest to darkest
  neutrallightest: waypointTokens.color.base.gray['50'].value,
  neutrallighter: waypointTokens.color.base.gray['100'].value,
  neutrallight: waypointTokens.color.base.gray['200'].value,
  neutralmid: waypointTokens.color.base.gray['300'].value,
  neutral: waypointTokens.color.base.gray['400'].value,
  neutraldark: waypointTokens.color.base.gray['600'].value,
  neutraldarker: waypointTokens.color.base.gray['700'].value,
  neutraldisabled: waypointTokens.color.base.gray['100'].value,

  // BLUES (B1  B4)  from lightest to darkest
  accent0lightest: waypointTokens.color.base.navy['50'].value,
  accent0lighter: waypointTokens.color.base.navy['100'].value,
  accent0light: waypointTokens.color.base.navy['300'].value,
  accent0: waypointTokens.color.base.navy['400'].value,
  accent0dark: waypointTokens.color.base.navy['600'].value,
  accent0darker: waypointTokens.color.base.navy['800'].value,

  // GREENS (G1  G4)  from lightest to darkest
  accent1lightest: waypointTokens.color.base.green['50'].value,
  accent1lighter: waypointTokens.color.base.green['100'].value,
  accent1light: waypointTokens.color.base.green['200'].value,
  accent1: waypointTokens.color.base.green['300'].value,
  accent1dark: waypointTokens.color.base.green['600'].value,
  accent1darker: waypointTokens.color.base.green['700'].value,

  // ORANGES (O1 - O4) - from lightest to darkest
  accent2light: waypointTokens.color.base['warm-red']['200'].value,
  accent2: waypointTokens.color.base['warm-red']['400'].value,
  accent2dark: waypointTokens.color.base['warm-red']['600'].value,
  accent2darker: waypointTokens.color.base['warm-red']['800'].value,

  // MAGENTAS (M1 - M4) - from lightest to darkest
  accent3light: waypointTokens.color.base.rose['300'].value,
  accent3: waypointTokens.color.base.rose['500'].value,
  accent3dark: waypointTokens.color.base.rose['700'].value,
  accent3darker: waypointTokens.color.base.rose['800'].value,

  // PURPLES (P1 - P4) - from lightest to darkest
  accent4light: waypointTokens.color.base.purple['300'].value,
  accent4: waypointTokens.color.base.purple['400'].value,
  accent4dark: waypointTokens.color.base.purple['700'].value,
  accent4darker: waypointTokens.color.base.purple['800'].value,

  // YELLOWS (Y1 - Y3) - from lightest to darkest
  accent5lightest: waypointTokens.color.base.amber['50'].value,
  accent5lighter: waypointTokens.color.base.amber['100'].value,
  accent5light: waypointTokens.color.base.amber['200'].value,
  accent5: waypointTokens.color.base.amber['300'].value,
  accent5dark: waypointTokens.color.base.amber['400'].value,
  accent5darker: waypointTokens.color.base.amber['700'].value,

  // REDS - (R1 - R3) - from lightest to darkest
  accent6lightest: waypointTokens.color.base.red['50'].value,
  accent6lighter: waypointTokens.color.base.red['100'].value,
  accent6light: waypointTokens.color.base.red['200'].value,
  accent6: waypointTokens.color.base.red['600'].value,
  accent6dark: waypointTokens.color.base.red['700'].value,

  // AQUAS (A1 - A4) - from lightest to darkest
  accent7light: waypointTokens.color.base.brown['200'].value,
  accent7: waypointTokens.color.base.brown['300'].value,
  accent7dark: waypointTokens.color.base.brown['600'].value,
  accent7darker: waypointTokens.color.base.brown['700'].value,

  // Branding Colors
  primarylight: waypointTokens.color.purpose.action.primary['400'].value,
  primary: waypointTokens.color.purpose.action.primary['700'].value,
  primarydark: waypointTokens.color.purpose.action.primary['900'].value,
  primaryhover: waypointTokens.color.background.purpose.button.primary.hover.value,
  primaryactive: waypointTokens.color.background.purpose.button.primary.active.value,

  // Basic Content & Text
  contentbgcolor: waypointTokens.color.background.context.shell.base.value,
  fontcolorbase: waypointTokens.color.font.purpose.base.value,

  // Links
  linkcolor: waypointTokens.color.font.context.ui.link.base.value,
  linkhovercolor: waypointTokens.color.font.context.ui.link.hover.value,
  linkactivecolor: waypointTokens.color.font.context.ui.link.active.value,

  // Nav - #1c4693
  leftnavcolor: '#1c4693',

  // Status Type Colors
  colorstatussuccess: waypointTokens.color.font.purpose.status.complete.value,
  colorstatuswarning: waypointTokens.color.font.purpose.warning.value,
  colorstatuserror: waypointTokens.color.font.purpose.status.failed.value,
  colorstatusinfo: waypointTokens.color.font.purpose.info.value,

  // Status BG Colors
  bgstatusalert: waypointTokens.color.background.purpose.alert.warning['50'].value,
  bgstatuswarning: waypointTokens.color.background.purpose.alert.warning['50'].value,
  bgstatuserror: waypointTokens.color.background.purpose.alert.error['50'].value,
  bgstatussuccess: waypointTokens.color.background.purpose.alert.success['50'].value,
  bgstatusinfo: waypointTokens.color.background.purpose.alert.info['50'].value,

  // Shadows
  shadowcolor: waypointTokens.color.base.gray['400'].value,

  // Inputs
  inputbgcolor: waypointInput.context['input-text'].style.base.base['fill-color'],
  inputbordercolor: waypointInput.context['input-text'].style.base.base['stroke-top-color'],
  inputborderfocus: waypointInput.context['input-text'].style.base.focus['stroke-outer-color'],

  // Placeholders
  placeholderdefault: waypointInput.context['input-text'].style.base.base['placeholder-color'],
  placeholderdisabled: waypointInput.context['input-text'].style.base.disabled['placeholder-color'],
  placeholderfocus: waypointInput.context['input-text'].style.base.focus['placeholder-color'],

  // Opacity
  defaultopacity: '0.7',
  opacitybackgroundcolor: `rgba(${waypointTokens.color.base.gray['400'].value}, 0.7)`,

  // Fonts
  fontfamilybase: waypointUi.context.ui.typography.base.css['font-family'].value,
  fontfamilyfixed: 'Menlo, Courier, "Andale Mono", monospace',

  fontsizebase: waypointTokens.size.font['100'].value,
  fontsizexxlarge: waypointTokens.size.font['500'].value,
  fontsizexlarge: waypointTokens.size.font['400'].value,
  fontsizelarger: waypointTokens.size.font['300'].value,
  fontsizelarge: waypointTokens.size.font['200'].value,
  fontsizemedium: waypointTokens.size.font['100'].value,
  fontsizesmall: waypointTokens.size.font['75'].value,
  fontsizexsmall: waypointTokens.size.font['50'].value,

  iconsizesmall: '10px',
  iconsizemedium: '14px',
  iconsizelarge: '18px',
  iconsizexlarge: '20px',

  fontweightthin: '100',
  fontweightlighter: '200',
  fontweightlight: '300',
  fontweightbase: '400',
  fontweightsemibold: '600',
  fontweightbold: '700',
  fontweightextrabold: '800',
  fontweightblack: '900',

  fontsizeh1: waypointUi.context.ui.typography.heading['06'].css['font-size'].value,
  fontsizeh2: waypointUi.context.ui.typography.heading['05'].css['font-size'].value,
  fontsizeh3: waypointUi.context.ui.typography.heading['04'].css['font-size'].value,
  fontsizeh4: waypointUi.context.ui.typography.heading['03'].css['font-size'].value,
  fontsizeh5: waypointUi.context.ui.typography.heading['02'].css['font-size'].value,
  fontsizeh6: waypointUi.context.ui.typography.heading['01'].css['font-size'].value,

  fontweighth1: waypointUi.context.ui.typography.heading['06'].css['font-weight'].value,
  fontweighth2: waypointUi.context.ui.typography.heading['05'].css['font-weight'].value,
  fontweighth3: waypointUi.context.ui.typography.heading['04'].css['font-weight'].value,
  fontweighth4: waypointUi.context.ui.typography.heading['03'].css['font-weight'].value,
  fontweighth5: waypointUi.context.ui.typography.heading['02'].css['font-weight'].value,
  fontweighth6: waypointUi.context.ui.typography.heading['01'].css['font-weight'].value,

  lineheightbase: waypointUi.context.ui.typography.base.css['line-height'].value,
  lineheightcomputed: waypointUi.context.ui.typography.base.css['line-height'].value
};

export class SassHelper {
  static getVariable(key: string): string {
    if (variables[key]) {
      return variables[key];
    }

    return key;
  }
}
