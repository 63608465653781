import React from 'react';
import { connect } from 'react-redux';
import { LocaleHelper } from '../core/LocaleHelper';

export const SET_LOCALE_KEY = '@@redux-intl/SET_LOCALE';

const initialState = {
  locale: 'en'
};

// Return action for locale change. This action should be invoked by the client app.
export const LocaleAction = (locale: string) => {
  return {
    type: SET_LOCALE_KEY,
    payload: {
      locale: locale
    }
  };
};

// This helper reducer change the locale state. This method should be called by the client app.
export const LocaleReducer = (state = initialState, action: any) => {
  LocaleHelper.setLocale(action.payload.locale);
  return Object.assign({}, state, action.payload);
};

const LocaleConnector = {
  mapStateToProps: (state: any) => {
    return {
      locale: state?.intl?.locale ?? ''
    };
  }
};

class ReduxIntl extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  shouldComponentUpdate(newState) {
    return this.props.locale !== newState.locale;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}

export const LocaleProvider = connect(LocaleConnector.mapStateToProps)(ReduxIntl);
