/* eslint @typescript-eslint/unbound-method: "off" */
import {
  __,
  append,
  compose,
  concat,
  flatten,
  flip,
  ifElse,
  is,
  prepend,
  unless
} from 'ramda';

export const reverseConcat = flip(concat);
export const appendAndFlatten = (xs) => compose(flatten, append(__, xs));
export const appendOrConcat = (xs) => ifElse(is(Array), concat(xs), append(__, xs));
// TypeScript doesn't like how prepend is called.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const prependAndFlatten = (xs) => compose(flatten, prepend(__, xs));
// TypeScript doesn't like how prepend is called.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const prependOrConcat = (xs) => ifElse(is(Array), reverseConcat(xs), prepend(__, xs));
export const arrayify = unless(is(Array), Array.of);
