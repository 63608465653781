interface IResponsiveClass {
  // name of the responsive column (small, medium...)
  name: string;
  // vdl prefix class name
  prefix: string;
  // next wider column vdl prefix class name
  nextColumnPrefix?: string;
}

interface IResponsiveRowValue {
  // horizontal alignment of the row
  horizontalAlign?: 'left' | 'center' | 'right' | 'around' | 'between';
  // vertical alignment of the row
  verticalAlign?: 'top' | 'middle' | 'bottom';
}

interface IResponsiveRow extends IResponsiveRowValue {
  // Extra small devices (portrait phones, more than than 0px)
  xsmall?: IResponsiveRowValue;
  // Small devices (landscape phones, more than 480px)
  small?: IResponsiveRowValue;
  // Medium devices (tablets, more than than 768px)
  medium?: IResponsiveRowValue;
  // Large devices (desktops, more than than 992px)
  large?: IResponsiveRowValue;
  // Extra Large devices (large desktops, more than than 1200px)
  xlarge?: IResponsiveRowValue;
  // Large devices (large desktops, more than 1440px)
  xxlarge?: IResponsiveRowValue;
  // [key: string]: IResponsiveRowValue;
}

interface IResponsiveColumn {
  // Extra small devices (portrait phones, more than than 0px)
  xsmall?: IResponsiveColumnValue;
  // Small devices (landscape phones, more than 480px)
  small?: IResponsiveColumnValue;
  // Medium devices (tablets, more than than 768px)
  medium?: IResponsiveColumnValue;
  // Large devices (desktops, more than than 992px)
  large?: IResponsiveColumnValue;
  // Extra Large devices (large desktops, more than than 1200px)
  xlarge?: IResponsiveColumnValue;
  // Large devices (large desktops, more than 1440px)
  xxlarge?: IResponsiveColumnValue;
  [key: string]: IResponsiveColumnValue;
}

interface IResponsiveColumnValue {
  // number of cells to allocate width
  cells?: number;
  // number of offset cells
  offset?: number;
  // number of cells to push
  push?: number;
  // number of cells to pull
  pull?: number;
  // visiblity of the column.
  visible?: boolean;
  // set text horizontal alignment
  align?: 'left' | 'right' | 'center';
  // set the column position
  position?: 'first' | 'last' | 'unordered';
}

interface IContent {
  content?: IContent[];
  className?: string;
  responsive?: {
    // developer can disable the responsive class and set their own className
    enabled?: boolean;
    // to set the grid type
    type?: 'container' | 'container-fluid';
    // to configure grid columns
    columns?: IResponsiveColumn;
    // to configure grid row
    row?: IResponsiveRow;
  };
}

const responsiveClasses: IResponsiveClass[] = [
  { name: 'xsmall', prefix: 'xs', nextColumnPrefix: 'sm' },
  { name: 'small', prefix: 'sm', nextColumnPrefix: 'md' },
  { name: 'medium', prefix: 'md', nextColumnPrefix: 'lg' },
  { name: 'large', prefix: 'lg', nextColumnPrefix: 'xl' },
  { name: 'xlarge', prefix: 'xl', nextColumnPrefix: 'xxl' },
  { name: 'xxlarge', prefix: 'xxl', nextColumnPrefix: null }
];

const defaultAutoColumn: string[] = ['vdl-col-xs'];

export class ResponsiveHelper {
  static registerResponsive(elementProps: IContent, content: any[]): any {
    let classNames: any[] = [];

    // if responsive object exists, then populate its className
    if (elementProps.responsive) {
      // by default responsive is enabled. If the developer has configured to disable the responsive, then don't process. Note: enabled can be undefined, so using === to verify the value as false
      if (elementProps.responsive.enabled === false) {
        delete elementProps.responsive;
        return elementProps;
      }

      if (elementProps.responsive.type) {
        // assign container class
        classNames = [(elementProps.responsive.type === 'container-fluid' ? 'vdl-container-fluid' : 'vdl-container')];
        // remove the property
        delete elementProps.responsive.type;
        // set its child to render as row
        ResponsiveHelper.setChildNodesWithDefaultResponsiveValue(content, true);
      }

      if (elementProps.responsive.row) {
        classNames = this.getResponsiveRow(elementProps);
        // Since this element is row, the child elements to render as column
        ResponsiveHelper.setChildNodesWithDefaultResponsiveValue(content, false);
      }
      else if (elementProps.responsive.columns) {
        classNames = this.getResponsiveColumn(elementProps);
        ResponsiveHelper.setChildNodesWithDefaultResponsiveValue(content, true);
      }

      delete elementProps.responsive;
    }

    if (elementProps.className) {
      // if the element has any additional className, append them
      classNames.push(elementProps.className);
    }

    elementProps.className = classNames.join(' ');
    return elementProps;
  }

  private static setChildNodesWithDefaultResponsiveValue(content: any[], isRow = false): void {
    // set child elements to render as row/column
    if (content) {
      content = Array.isArray(content) ? content : [content];

      content.forEach((child: any) => {
        // check if the element is div then apply the responsive properties
        if (child.type === 'div') {
          if (!child.properties) {
            child.properties = {};
          }

          if (!child.properties.responsive) {
            child.properties.responsive = {};
          }

          if (isRow && !child.properties.responsive.row) {
            child.properties.responsive.row = {};
          }
          else if (!isRow && !child.properties.responsive.columns) {
            child.properties.responsive.columns = {};
          }
        }
      });
    }
  }

  static getResponsiveRow = (node: IContent) => {
    let classNames = ['vdl-row'];

    if (node.responsive) {
      // if row has any additional responsive configuration, then append its classNames
      const responsiveRow = node.responsive.row;
      classNames = classNames.concat(ResponsiveHelper.getResponsiveRowValue(responsiveRow, responsiveClasses[0]));

      for (const responsiveClass of responsiveClasses) {
        if (responsiveRow.hasOwnProperty(responsiveClass.name)) {
          classNames = classNames.concat(
            ResponsiveHelper.getResponsiveRowValue(responsiveRow[responsiveClass.name], responsiveClass)
          );
        }
      }
    }

    return classNames;
  };

  // This method returns the responsive row alignment class names.
  private static getResponsiveRowValue = (node: any, classDefinition: IResponsiveClass) => {
    const classNames = [];

    if (node.horizontalAlign) {
      classNames.push(`vdl-flex-items-${classDefinition.prefix}-${node.horizontalAlign}`);
    }

    if (node.verticalAlign) {
      classNames.push(`vdl-flex-items-${classDefinition.prefix}-${node.verticalAlign}`);
    }

    return classNames;
  };

  // This method returns the responsive column class names based on the responsive object.
  static getResponsiveColumn = (node: IContent) => {
    if (node.responsive) {
      // if responsive object exists, then populate the class based on the specification.
      const responsiveColumn: IResponsiveColumn = node.responsive.columns;
      let classNames: string[] = [];
      const hiddenClasses: { down?: string; up?: string } = {};

      for (const responsiveClass of responsiveClasses) {
        if (responsiveColumn.hasOwnProperty(responsiveClass.name)) {
          classNames = classNames.concat(
            ResponsiveHelper.getColumnClass(responsiveColumn[responsiveClass.name], hiddenClasses, responsiveClass)
          );
        }
      }

      if (hiddenClasses.down) {
        classNames.push(`vdl-hidden-${hiddenClasses.down}-down`);
      }

      if (hiddenClasses.up) {
        classNames.push(`vdl-hidden-${hiddenClasses.up}-up`);
      }

      if (classNames.length === 0) {
        return defaultAutoColumn;
      }

      return classNames;
    }
    else {
      // default smart column which allocate equal the columns
      return defaultAutoColumn;
    }
  };

  private static getColumnClass = (columnValue: any, hiddenColumns: any, classDefinition: IResponsiveClass) => {
    const classNames: string[] = [];

    // if the columnValue is number, then assign its corresponding class and return
    if (columnValue > 0) {
      classNames.push(`vdl-col-${classDefinition.prefix}-${columnValue}`);
      return classNames;
    }

    const column = columnValue as IResponsiveColumnValue;

    if (column.cells >= 0) {
      // if cells is specified, use the vdl-col-*
      classNames.push(`vdl-col-${classDefinition.prefix}-${column.cells}`);
    }

    if (column.pull >= 0) {
      // if pull is specified, use the vdl-pull-*-{pull}
      classNames.push(`vdl-pull-${classDefinition.prefix}-${column.pull}`);
    }

    if (column.push >= 0) {
      // if push is specified, use the vdl-push-*-{push}
      classNames.push(`vdl-push-${classDefinition.prefix}-${column.push}`);
    }

    if (column.offset >= 0) {
      // if offset is specified, use the vdl-offset-*-{offset}
      classNames.push(`vdl-offset-${classDefinition.prefix}-${column.offset}`);
    }

    if (column.align) {
      // if align is specified, use the vdl-text-*-{align}
      classNames.push(`vdl-text-${classDefinition.prefix}-${column.align}`);
    }

    if (column.position) {
      // if position of the column is specified, use the vdl-flex-*-{position}
      classNames.push(`vdl-flex-${classDefinition.prefix}-${column.position}`);
    }

    // check for the visible columns. The column can be set to lower bound (vdl-hidden-*-down) and upper bound (vdl-hidden-*-up), which defines the visibility range.
    // By default, the responsive class is assigned to the lower bound.
    // Note: visible can be undefined, so using === to verify the value
    if (column.visible === true) {
      // if it is configured as visible, then set its next wider column as upper limit.
      hiddenColumns.up = classDefinition.nextColumnPrefix;
    }
    else if (column.visible === false && !hiddenColumns.up) {
      hiddenColumns.down = classDefinition.prefix;
    }

    return classNames;
  };
}
