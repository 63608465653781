import { ComponentManager } from '../core/ComponentManager';
import { ModelHelper } from '../core/ModelHelper';
import { RenderHelper } from '../core/RenderHelper';

export default function globalContentSelectorRenderer({ viewName, view, model }) {
  // Resolve the model properties of ViewSelector
  const viewProperties = ModelHelper.resolve(view.properties, model, viewName);

  const rootViewName = viewProperties.rootViewName;
  let selectorCode = viewProperties.selectorCode;

  if (!rootViewName) {
    console.error('globalContentSelectorRenderer(): The rootViewName property is required.');
    return null;
  }

  if (!ComponentManager.isComponentRegistered(rootViewName)) {
    console.error(`globalContentSelectorRenderer(): View [${rootViewName}] does not exist.`);
    return null;
  }

  // No value is treated as an empty selector code, but 0 is considered a value.
  if ((!selectorCode && selectorCode !== 0) || (selectorCode === 'DEFAULT')) {
    selectorCode = '';
  }

  const realViewName = rootViewName + selectorCode;

  // Render the selected view, if it exists. Otherwise, render the root view.
  const realView = Object.assign({}, view, {
    type: ComponentManager.isComponentRegistered(realViewName) ? realViewName : rootViewName,
    properties: viewProperties
  });

  return RenderHelper.renderView(realView, model, realViewName);
}
