import { NativeHelper } from './NativeHelper';

export interface IDeviceInformation {
  userAgent: string;
  platform: string;
  isMobile: boolean;
  isTablet: boolean;
  isIos: boolean;
  isAndroid: boolean;
  version: number;
}

export class DeviceHelper {
  private static deviceInformation: IDeviceInformation;
  private static CORDOVA_VERSION = 'ADP-Cordova-Version';
  private static CORDOVA_TYPE = 'ADP-Cordova-Type';
  private static CORDOVA_CONTAINER = 'Container';

  static initialize() {
    const device: any = {};

    device.userAgent = navigator.userAgent.toLowerCase();
    device.platform = navigator.platform.toLowerCase();
    device.touch = typeof (window.ontouchstart) !== 'undefined';

    // get android details
    // device.isAndroid = (/android/.test(device.userAgent));
    device.isAndroid = device.userAgent.includes('android');

    if (device.isAndroid) {
      device.version = parseFloat(device.userAgent.slice(device.userAgent.indexOf('android') + 8));

      if (device.silk) {
        device.version = 2.0;
      }

      device.isMobile = device.userAgent.includes('mobile');
      device.isTablet = !device.userAgent.includes('mobile');
    }

    // get ios details
    const isIphone = (/ip(hone|od)/.test(device.platform));
    const isIpad = device.platform.includes('ipad');

    device.isIos = (isIphone || isIpad);

    if (device.isIos) {
      device.isMobile = isIphone;
      device.isTablet = isIpad;

      const version = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);

      device.version = (version != null) ? version[0].split(' ')[1].split('_').join('.') : 'n/a';
    }

    DeviceHelper.deviceInformation = device;
  }

  static isCordova() {
    if (window['cordova']) {
      return true;
    }

    const cookies = document.cookie;

    if (cookies) {
      return cookies.includes(DeviceHelper.CORDOVA_TYPE) && cookies.includes(DeviceHelper.CORDOVA_VERSION);
    }

    return false;
  }

  static isADPMobileApp() {
    return DeviceHelper.isMobileDevice() && DeviceHelper.isCordova();
  }

  static getADPMobileContainer() {
    if (DeviceHelper.isADPMobileApp() && window[DeviceHelper.CORDOVA_CONTAINER]) {
      return window[DeviceHelper.CORDOVA_CONTAINER];
    }

    return undefined;
  }

  static isMobileDevice = () => DeviceHelper.deviceInformation.isMobile || DeviceHelper.deviceInformation.isTablet;
  static isTablet = () => DeviceHelper.deviceInformation.isTablet;
  static isPhone = () => DeviceHelper.deviceInformation.isMobile;
  static isAndroid = () => DeviceHelper.deviceInformation.isAndroid;
  static isIOS = () => DeviceHelper.deviceInformation.isIos;

  static async containerVersion() {
    return NativeHelper.getAppContext().then((appContext) => appContext.version);
  }
}
