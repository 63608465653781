import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

export interface IReduxMapper {
  mapStateToModel?: (state: any, props?: any) => any;
  mapDispatchToModel?: (dispatch: (action: any) => void, props?: any) => any;
}

export class ReduxHelper {
  private static viewConnectors: any = {};

  static connectView(viewName: string, mappers: IReduxMapper) {
    if (this.viewConnectors[viewName]) {
      console.info(`ReduxHelper.connectView(): The view connector for view [${viewName}] is being replaced.`);
    }

    this.viewConnectors[viewName] = mappers;
  }

  static getConnector(viewName: string): IReduxMapper {
    return this.viewConnectors[viewName];
  }

  // Create the Redux store and expose this function for use by application unit tests.
  static createStore(reducer?: (state: any, action: any) => any) {
    const middleware = [];
    const noopReducer = (state: any) => state;

    if (localStorage.getItem('adpWFNReduxLogger')) {
      middleware.push(createLogger());
    }

    return configureStore({
      reducer: reducer || noopReducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware)
    });
  }
}
